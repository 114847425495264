// Button
import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Button.module.sass';
import Icon from '../Icon';
import { IconName } from '../Icon/Icons';
import Link from 'next/link';

export interface ButtonProps extends PropsWithChildren {
  type: 'primary' | 'light' | 'dark' | 'red' | 'outline' | 'layer';
  icon?: IconName;
  text?: string;
  textStyle?: '1' | '2' | '3';
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  internalLink?: boolean;
  externalLink?: boolean;
  href?: string;
  linkClassName?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  icon,
  text,
  type,
  textStyle = '1',
  fullWidth = true,
  disabled,
  children,
  className,
  internalLink = false,
  externalLink = false,
  href = '',
  linkClassName,
}) => {
  const linkWrapper = (button: JSX.Element) => {
    if (internalLink) {
      return (
        (<Link
          href={href || ''}
          passHref
          className={cn(linkClassName, { [styles.fullWidth]: fullWidth })}>

          {button}

        </Link>)
      );
    } else if (externalLink) {
      return (
        <a
          className={cn(linkClassName, { [styles.fullWidth]: fullWidth })}
          href={href.includes('http') ? href : `//${href}`}
          target="_blank"
          rel="noreferrer"
        >
          {button}
        </a>
      );
    }
    return button;
  };

  return linkWrapper(
    <button
      className={cn(styles.button, className, {
        [styles.typography1]: textStyle === '1',
        [styles.typography2]: textStyle === '2',
        [styles.typography3]: textStyle === '3',

        [styles.primary]: type === 'primary',
        [styles.outline]: type === 'outline',
        [styles.light]: type === 'light',
        [styles.dark]: type === 'dark',
        [styles.red]: type === 'red',
        [styles.layer]: type === 'layer',
        [styles.disabled]: disabled,

        [styles.fullWidth]: fullWidth,
      })}
      onClick={disabled ? undefined : onClick}
    >
      {icon && <Icon name={icon} className={styles.icon} />}
      {text || children}
    </button>
  );
};

export default Button;
