import React from "react";
import AuthView from "components/views/Auth";
import { NextPageWithLayout } from "types/page";
import { getRegistrationLayout } from "components/containers/RegistrationLayout";

/**
 * Home: The Landing page of the web app
 * @return {NextPageWithLayout}
 */
const Home: NextPageWithLayout = () => <AuthView />;

Home.getLayout = getRegistrationLayout;

export default Home;
