import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Tooltip.module.sass';
import { IconName } from '../Icon/Icons';
import Icon from '../Icon';

export interface TooltipProps extends PropsWithChildren {
  className?: string;
  contentClassName?: string;
  tooltipContent: React.ReactNode;
  place?: 'top' | 'left' | 'bottom' | 'right';
  iconColor?: string;
  icon?: IconName;
  iconSize?: number;
}

const Tooltip: React.FC<TooltipProps> = ({
  className,
  contentClassName,
  tooltipContent,
  place = 'top',
  icon,
  iconSize = 16,
  children,
}) => {
  return (
    <div className={cn(styles.tooltip, className)}>
      <span
        className={cn(styles.content, contentClassName, {
          [styles.top]: place === 'top',
          [styles.left]: place === 'left',
          [styles.bottom]: place === 'bottom',
          [styles.right]: place === 'right',
        })}
      >
        <p>{tooltipContent}</p>
      </span>
      {children}
      {icon && <Icon name={icon} size={iconSize} />}
    </div>
  );
};

export default Tooltip;
