import React, { ChangeEventHandler, useState } from 'react';
import styles from './AuthForm.module.sass';
import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';
import { validateEmail, validatePasswordOrReturnError } from 'utils/validators';
import cn from 'classnames';
import { useAuth } from 'hooks/useAuth';
import { API_RESPONSE_STATUS, AuthResponse } from 'types/auth';
import Loader from 'components/common/Loader';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import useToast from 'components/common/Toast';
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from 'constants/links';

export type AuthFormState = 'login' | 'sign-up';

interface AuthFormProps {
  className?: string;
  authFormState: AuthFormState;
  toggleFormState: () => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  className,
  authFormState,
  toggleFormState,
}) => {
  const { login, signup, googleLogin, isLoading, isAuthenticated } = useAuth();
  const [authForm, setAuthForm] = useState({
    email: '',
    password: '',
  });
  const [emailError, setEmailError] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const { triggerToast } = useToast();

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value, id },
  }) => {
    setAuthForm((prev) => ({ ...prev, [id]: value }));
    if (id === 'email') {
      setEmailError(validateEmail(value) ? '' : 'Invalid email');
    } else if (id === 'password') {
      const validOrError = validatePasswordOrReturnError(value);
      setPasswordError(typeof validOrError === 'string' ? validOrError : '');
    }
  };

  const handleGoogleLogin = async () => {
    const { status, message } = await googleLogin();
    if (status === API_RESPONSE_STATUS.ERROR) {
      setEmailError(message);
      triggerToast(message);
    }
  };

  const handleSubmit = async () => {
    const { email, password } = authForm;
    let response = {} as AuthResponse;
    try {
      if (authFormState === 'login') {
        response = await login(email, password);
      } else {
        response = await signup(email, password);
      }
      if (response.status === 'error') {
        const { message } = response;
        if (message.includes('email')) {
          setEmailError(message);
        } else {
          setPasswordError(message);
        }
      }
    } catch (error) {
      Bugsnag.notify(
        'Email Password Error',
        addErrorMetaData('form', {
          email,
          authFormState,
          response,
        })
      );
    }
  };

  const buttonDisabled = emailError !== '' || passwordError !== '';

  const handleEmailKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter' || key === 'Tab') {
      document.getElementById('password')?.focus();
    }
  };

  const handlePasswordKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  return isLoading || isAuthenticated ? (
    <div className={styles.loadingContainer}>
      <Loader />
    </div>
  ) : (
    <div className={cn(styles.authContainer, className)}>
      {authFormState === 'sign-up' ? (
        <>
          <p className={styles.oAuthSignUp}>
            Sign up with one of your accounts
          </p>
        </>
      ) : (
        <p className={styles.oAuthSignUp}>Sign in with one of your accounts</p>
      )}
      <Button
        className={styles.googleButton}
        type="dark"
        icon="google"
        text="Google"
        onClick={handleGoogleLogin}
      />
      <p className={styles.emailPrompt}>Or continue with your email address.</p>
      <TextInput
        id="email"
        type="email"
        placeholder="Your email"
        onChange={handleChange}
        value={authForm.email}
        className={styles.emailField}
        startIcon="mail"
        validation={typeof emailError === 'string'}
        error={emailError}
        onKeyUp={handleEmailKeyUp}
      />
      <TextInput
        id="password"
        type="password"
        placeholder="New password"
        onChange={handleChange}
        value={authForm.password}
        className={styles.passwordField}
        startIcon="lock"
        validation={typeof passwordError === 'string'}
        error={passwordError}
        onKeyUp={handlePasswordKeyUp}
      />
      <Button
        type="primary"
        textStyle="2"
        className={styles.continueButton}
        onClick={handleSubmit}
        disabled={buttonDisabled}
      >
        Continue
      </Button>
      {authFormState === 'sign-up' ? (
        <>
          <p className={styles.tos}>
            By creating an account, you agree to our
            <br />
            <a href={TERMS_OF_SERVICE} target="_blank" rel="noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </p>
          <hr className={styles.hr} />
          <Button
            type="light"
            textStyle="2"
            onClick={toggleFormState}
            className={styles.toggleAuthButton}
          >
            Already Have An Account? Sign In
          </Button>
        </>
      ) : (
        <a className={styles.signInLink} onClick={toggleFormState}>
          Don't have an account? Sign Up
        </a>
      )}
    </div>
  );
};

export default AuthForm;
