import React, { useState } from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import { IconName } from "../Icon/Icons";
import Tooltip, { TooltipProps } from "../Tooltip";

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  classLabel?: string;
  classInput?: string;
  label?: string;
  startIcon?: IconName;
  startAdornment?: string;
  containerRef?: React.RefObject<HTMLInputElement>;
  validation?: boolean;
  error?: string;
  gutterBottom?: boolean;
  fullWidth?: boolean;
  tooltip?: string;
  place?: TooltipProps["place"];
  theme?: "light" | "dark";
}

// TODO: Use theme instead class labels

const TextInput: React.FC<InputProps> = ({
  className,
  classLabel,
  classInput,
  label,
  disabled,
  startIcon,
  startAdornment,
  containerRef,
  validation,
  error,
  children,
  gutterBottom = true,
  fullWidth = true,
  place = "right",
  tooltip,
  theme = "dark",
  ...props
}) => {
  // https://stackoverflow.com/a/30619329
  const [focused, setFocused] = useState<boolean>(false);
  const hasError = typeof error === "string" && error !== "";

  return (
    <div
      ref={containerRef}
      className={cn(
        {
          [styles.width]: fullWidth,
          [styles.gutterBottom]: gutterBottom,
          [styles.empty]: disabled,
          [styles.adornment]: startAdornment,
          [styles.error]: !focused && hasError,
          [styles.startIconContainer]: startIcon,
        },
        className
      )}
    >
      {label && (
        <div
          className={cn(classLabel, styles.label, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              tooltipContent={tooltip}
              icon="info"
              place={place}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        {startIcon && (
          <Icon
            className={styles.startIcon}
            name={startIcon}
            height={24}
            width={24}
          />
        )}
        {startAdornment && (
          <div
            className={cn(styles.startAdornment, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            {startAdornment}
          </div>
        )}
        <input
          disabled={disabled}
          className={cn(styles.input, classInput, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
          {...props}
          onFocus={(e) => {
            setFocused(true);
            if (props.onFocus) {
              props.onFocus(e);
            }
          }}
          onBlur={(e) => {
            setFocused(false);
            if (props.onBlur) {
              props.onBlur(e);
            }
          }}
        />
        {children && children}
        {!focused &&
        typeof props.value === "string" &&
        props.value.length > 0 &&
        (validation || error) ? (
          <>
            {typeof children === "undefined" && (
              <div className={styles.validationContainer}>
                <Icon
                  className={styles.validationIcon}
                  name={hasError ? "warning" : "check"}
                />
              </div>
            )}
            <p className={styles.errorText}>{error}</p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TextInput;
