import React, { useEffect, useState } from 'react';
import styles from './Auth.module.sass';
import AuthForm, {
  AuthFormState,
} from 'components/views/Auth/AuthForm/AuthForm';
import { useRouter } from 'next/router';
import { useAuth } from 'hooks/useAuth';
import ROUTES from 'config/routes';
import { APPLICATION_STATUSES, isCreator } from 'types/creator';
import { getAuthRedirect } from 'components/containers/AuthProtection';

const AuthView: React.FC = () => {
  const router = useRouter();
  const { isLoading, authProfile } = useAuth();
  const [authFormState, setAuthFormState] = useState<AuthFormState>('sign-up');

  const toggleAuthFormState = () => {
    setAuthFormState((prev) => (prev === 'sign-up' ? 'login' : 'sign-up'));
  };

  useEffect(() => {
    if (!isLoading && typeof authProfile !== 'undefined') {
      const sessionRedirect = getAuthRedirect();
      if (typeof sessionRedirect === 'string') {
        router.push(sessionRedirect);
        return;
      }
      const { creator } = authProfile;
      if (
        isCreator(creator) &&
        creator.applicationStatus !== APPLICATION_STATUSES.NEW
      ) {
        router.push(ROUTES.ApplicationStatus.getPath());
      } else {
        router.push(ROUTES.Registration.getPath());
      }
    }
  }, [isLoading, router, authProfile]);

  return (
    <div className={styles.content}>
      {authFormState === 'sign-up' ? (
        <>
          <h1 className={styles.title}>
            Shoot content,
            <br />
            make cash
          </h1>
          <p className={styles.subtitle}>
            We add photo, video, and social media brand partnerships daily.
          </p>
        </>
      ) : (
        <h1 className={styles.title}>Welcome back!</h1>
      )}
      <AuthForm
        className={styles.authContainer}
        authFormState={authFormState}
        toggleFormState={toggleAuthFormState}
      />
    </div>
  );
};

export default AuthView;
